<template>
	<div class="basket-confirm" v-if="allowedToRender && hasProductsInBasket">
		<div class="basket-confirm__terms">
			<Checkbox :data="{ value: 'qterms' }" v-model="terms">
				<span v-html="termsAndConditionsMarkup" />
			</Checkbox>
		</div>

		<div v-if="showCovid" class="basket-confirm__terms">
			<Checkbox v-model="covidTerms" :data="{ value: 'covid' }">
				<span>{{ $t('checkout.covid_terms', { brand: brand.name }) }}</span>
			</Checkbox>
		</div>
	</div>
</template>

<script>
import Checkbox from '@/components/ui/Checkbox';

export default {
	name: 'BasketTerms',

	data() {
		return {
			terms: null,
			covidTerms: null,
			customError: false,
			checkOutErrors: null,
			allowedToRender: true,
			productAlreadyPurchased: false,
			productName: ''
		};
	},

	components: {
		Checkbox
	},

	computed: {
		brand() {
			return this.$store.getters.getBrand;
		},

		locale() {
			return this.$store.getters.getLocale;
		},

		ossConfig() {
			return this.$store.getters.getOssConfig;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		hasProductsInBasket() {
			return Object.keys(this.productsInBasket).length > 0;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},
		isMembershipSelected() {
			return this.productsInBasket.hasOwnProperty('membership');
		},

		isTravelOrder() {
			return this.productsInBasket.travel;
		},

		termsAndConditionsMarkup() {
			const bleepTerms =
				this.locale.code == 'nl' ? 'https://www.justbleep.it/nl/terms' : 'https://www.justbleep.it/terms';
			const paylogicTerms =
				this.locale.code == 'nl'
					? 'https://www.paylogic.com/nl/terms-conditions'
					: 'https://www.paylogic.com/en/terms-conditions';

			const isPaylogic = this.shopConfig.shopInit?.paymentProvider !== 'adyen';

			const paymentProvider = isPaylogic
				? `<a href=${paylogicTerms} target="_blank">Paylogic</a>`
				: `<a href=${bleepTerms} target="_blank">Bleep</a>`;

			const termsConditions = label =>
				`<a href="${
					this.shopConfig.shopSettings?.fields?.termsConditions ?? '#'
				}" target="_blank">${label}</a>`;

			const privacyPolicy = label =>
				`<a href="${this.shopConfig.shopSettings?.fields?.privacyPolicy ?? '#'}" target="_blank">${label}</a>`;

			let merchandise =
				'<a href="https://assets.ctfassets.net/h5h7l03rs5ut/1kTkZJYxIDWG7efZdR816E/d4130dec5335c2283a45d22586ea9f20/T_C_Merchandise_EN.pdf" target="_blank">Superstruct Merchandise Services</a>';
			if (['zwartecross', 'mananamanana'].includes(this.brand)) {
				merchandise =
					'<a href="https://www.zwartecross.nl/wp-content/uploads/sites/3/2024/10/Algemene-voorwaarden-FF-Merchandise.pdf" target="_blank">algemene webshopvoorwaarden van Feestfabriek Merchandise B.V.</a>';
			}
			const transport =
				'<a href="https://www.partybussen.nl/algemene-voorwaarden" target="_blank">Partybussen</a>';

			const insurance =
				'<a href="https://www.xcover.com/en/pds/booking-refund-protection-eu" target="_blank">XCover</a>';

			const travel = '<a href="https://dancetravel.nl/terms" target="_blank">Superstruct Travel & Stay</a>';

			const hasMerchandise = (this.productsInBasket?.merchandise ?? []).length > 0;
			const hasTransport = (this.productsInBasket?.transportation ?? []).some(
				item => item.type?.type === 'bus_travel'
			);
			const hasInsurance = (this.productsInBasket?.insurance ?? []).some(item => item.type?.type === 'insurance');
			const hasTravel = (this.productsInBasket?.travel ?? []).length > 0;

			let allTermsConditions = this.$t('checkout.terms', {
				termsConditions: termsConditions(this.$t('label.terms_and_conditions')),
				title: this.shopConfig.title,
				privacyPolicy: privacyPolicy(this.$t('label.privacy_policy'))
			});

			if (hasMerchandise) allTermsConditions += this.$t('checkout.terms_merchandise', { merchandise });
			if (hasTravel) allTermsConditions += this.$t('checkout.terms_travel', { travel });
			if (hasTransport) allTermsConditions += this.$t('checkout.terms_transport', { transport });
			if (hasInsurance) allTermsConditions += this.$t('checkout.terms_insurance', { insurance });

			allTermsConditions += this.$t('checkout.terms_payment', { paymentProvider });

			return allTermsConditions;
			// return this.locale.code == 'nl'
			// 	? `Ik ga akkoord met de ${allTerms}. Door te accepteren, gaat u ook akkoord met de algemene voorwaarden van ${paymentProvider}.`
			// 	: `I accept the ${allTerms}. By accepting, you also agree to the terms & conditions of ${paymentProvider}.`;
		},

		showCovid() {
			// Should be true by default, also if 'showCovidTerms' isn't filled. So only hide if set to 'false' in CTF.
			return this.shopConfig.showCovidTerms !== false;
		}
	},

	watch: {
		terms(val) {
			this.$store.commit('setIsTermsAccepted', val);
		},
		covidTerms(val) {
			this.$store.commit('setIsCovidTermsAccepted', val);
		}
	},

	mounted() {
		this.checkOutErrors = !!this.shopCart.checkoutErrors ? [...this.shopCart.checkoutErrors] : [];
		// this.checkIfProductBoughtEarlier();
	}
};
</script>

<style lang="scss">
$component: 'basket-confirm';

.#{$component} {
	display: block;

	// padding: $global-spacing;
	// background-color: rgba(black, 0.5);

	&__terms {
		font-size: 14px;
		line-height: 1.4;

		input + label {
			font-size: inherit;
			text-transform: none;
		}

		p {
			margin-bottom: 0.5em;
		}
	}

	&__errors {
		padding: $global-spacing;
		margin-bottom: calc($global-spacing / 2);

		color: $color-dark-blue;
		line-height: 1.2;
		font-size: 16px;

		background-color: $color-white;

		p {
			margin-bottom: 6px;

			color: $color-red;
			font-family: $font-bold;
		}

		ul {
			list-style-type: disc;
			padding-left: 1.2em;
		}
	}

	&__cta {
		padding-top: 20px;
	}

	@include sm {
		&__ammount {
			display: flex;
			justify-content: space-between;

			text-transform: capitalize;
			font-size: 18px;

			padding: 0 0 0 $global-spacing--mobile;

			background: none;
		}

		&__terms {
			font-size: 14px;

			label {
				font-size: inherit;
			}
		}
	}
}
</style>
